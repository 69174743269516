html {
  scroll-behavior: smooth;
}
body {
  background-color: #DCF1DA;
  /* background-color: #9ae4a7; */
  /* background-color: #E1F9F9; */
  /* background-color: lightblue; */
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  max-width: 100vw;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
}
/*  -  -  -  -  -  -  -  -  -  -  -  -  - */
header {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: #9ae4a7;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  font-family: "Benne", serif;
  font-size: 20px;
}

.logo {
  height: 30px;
  align-items: center;
}

.logo:hover {
  transform: scale(1.1);
}

a {
  color: #423f3f;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.4s ease;
}

a:hover {
  transform: scale(1.1);
  color: #0d694c;
  font-weight: 800;
}
/*  -  -  -  -  -  -  -  - -  -  -  -  - */
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-image: url(./images/Skyplantsbokeh.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding-top: 30vh;
  padding-bottom: 50vh;
}

.main-container .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70vw;
  height: 30vh;
  max-width: 1300px;
  max-height: 550px;
  min-width: 300px;
  min-height: 180px;
  margin: 0 auto;
  /* border: 3px solid #575151; */
  box-shadow: 8px 8px 8px #302a2a99;
}
/*  -  -  -  -  -  -  -  - -  -  -  -  - */
h2 {
  text-decoration: underline;
  margin-top: 80px;
  margin-bottom: 0;
  font-family: "Benne", serif;
  font-size: 36px;
  color: #4d4949;
}
/*  -  -  -  -  -  -  -  - -  -  -  -  - */
.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  padding-top: 150px;
  padding-bottom: 100px;
}

.about-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.about-section img {
  width: 40vw;
  margin: 30px;
  margin-top: 0;
  border: 3px solid black;
  box-shadow: 8px 8px 8px #302a2a99;
  border-radius: 25px;
}
.about-section #lang {
  width: 40vw;
  margin: 30px;
  margin-top: 20;
  border: 3px solid black;
  box-shadow: 8px 8px 8px #302a2a99;
  border-radius: 25px;
}
/*  -  -  -  -  -  -  -  -  - -  -  -  - */
.projects-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  padding-top: 50px;
  padding-bottom: 450px;
}

.projects-container img {
  width: 300px;
  height: 300px;
  margin: 20px;
  margin-top: 0;
  border: 3px solid black;
  box-shadow: 8px 8px 8px #302a2a99;
}
.projects-container h3 {
  margin-top: 0;
}

.projects-container .text {
  width: 400px;
  min-width: 400px;
  height: 215px;
  padding: 20px 15px 30px 15px;
  margin-left: 50px;
  margin-top: 20px;
  background-color: #d8d5d5;
  border-radius: 12%;
  font-family: "Benne", serif;
  font-size: 20px;
}

.projects-section-1 {
  display: flex;
  flex-direction: row;
  margin-left: 10vw;
}
.projects-section-2 {
  display: flex;
  flex-direction: row;
  margin-left: 18vw;
}
.projects-section-3 {
  display: flex;
  flex-direction: row;
  margin-left: 26vw;
}
.projects-section-4 {
  display: flex;
  flex-direction: row;
  margin-left: 34vw;
}
/*  -  -  -  -  -  -  -  -  -  - -  -  - */
hr {
  border-width: 3px;
  color: black;
}

.connect-container {
  width: 100vw;
  /* margin-top: 20px; */
  padding-bottom: 60px;
}

.connect-container h2 {
  margin-top: 60px;
}

footer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  background-color: #9ae4a7;
  /* background-color: #a6f188; */
  padding: 15px;
  bottom: 0;
  left: 0;
}

.footer-text {
  font-size: 28px;
  color: black;
}

/*  - - - - R E S P O N S I V E - - - - */
@media (max-width: 500px) {
  img {
    width: 85vw;
  }

  .projects-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    padding-top: 50px;
    padding-bottom: 450px;
  }

}
